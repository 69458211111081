/**
* font-size
**/
/**
* color
**/
.asideWrapper {
  width: 220px;
  float: left; }
  .asideWrapper .asideTitle {
    display: block;
    margin-bottom: 12px; }
  .asideWrapper .asideMenus {
    margin-bottom: 24px; }
    .asideWrapper .asideMenus .asideSubTitle,
    .asideWrapper .asideMenus .menuItem {
      position: relative;
      margin-bottom: 8px;
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 54px;
      display: block;
      width: 220px;
      min-height: 56px;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      line-height: 24px;
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.05); }
    .asideWrapper .asideMenus .asideSubTitle img {
      position: absolute;
      top: 50%;
      right: 19px;
      z-index: 10;
      margin-top: -8px;
      width: 16px;
      height: 16px;
      transition: all 0.15s ease-in-out; }
    .asideWrapper .asideMenus .menuItem.active {
      background-color: rgba(255, 255, 255, 0.15) !important; }
      .asideWrapper .asideMenus .menuItem.active::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 4px;
        background-color: #c62f6b; }
    .asideWrapper .asideMenus .menuItem.open .asideSubTitle {
      background-color: rgba(255, 255, 255, 0.15); }
      .asideWrapper .asideMenus .menuItem.open .asideSubTitle img {
        transform: rotate(90deg); }
    .asideWrapper .asideMenus .menuItem.open .asideSubMenus {
      display: block !important; }
    .asideWrapper .asideMenus .menuItem.hasChildren {
      padding: 0;
      background-color: transparent; }
      .asideWrapper .asideMenus .menuItem.hasChildren .asideSubMenus {
        display: none; }
        .asideWrapper .asideMenus .menuItem.hasChildren .asideSubMenus .menuItem {
          padding-left: 80px; }
          .asideWrapper .asideMenus .menuItem.hasChildren .asideSubMenus .menuItem::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 54px;
            z-index: 10;
            margin-top: -5px;
            width: 10px;
            height: 10px;
            background: #d8d8d8;
            border-radius: 50%; }
