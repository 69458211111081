/**
* font-size
**/
/**
* color
**/
.wrapper {
  max-width: 560px; }
  .wrapper .modalTitle {
    max-width: 383px;
    margin: 0 auto 24px; }
  .wrapper .dacInfo {
    margin-bottom: 16px;
    flex-direction: column; }
    .wrapper .dacInfo .dacPhoto {
      margin: 0 auto 16px;
      display: block;
      width: 100px;
      height: 100px;
      background: #d8d8d8;
      border: 1px solid #979797;
      border-radius: 50%;
      overflow: hidden; }
      .wrapper .dacInfo .dacPhoto img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%; }
  .wrapper .inviterCodeCopy {
    margin: 0 auto 12px;
    width: 337px; }
  .wrapper .modalPromptText {
    margin-top: 16px;
    line-height: 25px; }
  .wrapper .modalActionBar {
    margin-top: 71px; }
  .wrapper .connectBtn {
    width: 160px; }
    .wrapper .connectBtn img {
      margin-right: 8px; }
