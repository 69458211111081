.backBtn {
    height: 64px;

    .text {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.joinButton {
    padding: 20px;
    display: inline-block;
    min-width: 200px;
    height: 64px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    border: 1px solid #cd3270;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    color: #cd3270;
    line-height: 22px;
    vertical-align: top;
    white-space: nowrap;

    &:disabled {
        cursor: not-allowed;
        border: 1px solid rgba(255, 255, 255, 0.5);
        color: rgba(255, 255, 255, 0.5);
    }
}
