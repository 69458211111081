/**
* font-size
**/
/**
* color
**/
.copy {
  position: relative;
  padding: 16px 53px 16px 16px;
  display: flex;
  width: 100%;
  height: 48px;
  background: #110c12;
  border-radius: 4px; }
  .copy .copyContainer {
    margin: 0;
    overflow: hidden; }
    .copy .copyContainer .copyLabel {
      margin-right: 8px;
      min-width: 90px;
      white-space: nowrap; }
    .copy .copyContainer .code {
      flex: 1;
      overflow: hidden; }
      .copy .copyContainer .code .codeValue {
        display: block;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
  .copy .copyMenu {
    position: absolute;
    top: 50%;
    right: 13px;
    z-index: 1;
    margin-top: -10.5px;
    width: 21px;
    height: 21px;
    cursor: pointer; }
    .copy .copyMenu img {
      width: 100; }
