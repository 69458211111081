/**
* font-size
**/
/**
* color
**/
.uploadPhoto {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #cd3270;
  overflow: hidden;
  cursor: pointer; }
  .uploadPhoto .previewImg {
    width: 100%;
    height: 100%;
    border-radius: 50%; }
  .uploadPhoto .editPhotoLabel {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding-top: 5px;
    height: 23px;
    line-height: 23px;
    background-color: rgba(7, 2, 13, 0.7);
    text-align: center; }
    .uploadPhoto .editPhotoLabel .iconEdit {
      width: 14px;
      height: 14px;
      vertical-align: top; }
