/**
* font-size
**/
/**
* color
**/
.container {
  margin: 0 auto;
  width: 1038px; }

.dacFormTabs {
  width: 634px;
  height: 711px;
  float: right; }
  .dacFormTabs :global .MuiTabs-indicator {
    top: 0; }
  .dacFormTabs :global .MuiTab-root {
    height: 64px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    text-transform: inherit; }
    .dacFormTabs :global .MuiTab-root.Mui-selected {
      background-color: rgba(0, 0, 0, 0.8); }
  .dacFormTabs :global .tabPanelItem {
    height: 679px;
    background-color: rgba(0, 0, 0, 0.8); }
    .dacFormTabs :global .tabPanelItem .MuiBox-root {
      padding-right: 57px;
      padding-left: 57px; }

.dacForm {
  position: relative;
  min-height: 631px; }
  .dacForm .dacPhoto {
    margin-bottom: 16px;
    width: 80px;
    height: 80px; }
  .dacForm .dacUploadTip {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    line-height: 28px; }
  .dacForm .labelHelp {
    margin-left: 8px;
    display: inline-block; }
    .dacForm .labelHelp .iconHelp {
      width: 18px;
      height: 18px;
      vertical-align: -4px; }
  .dacForm .dacPhotoControl {
    margin-bottom: 24px;
    text-align: center; }
    .dacForm .dacPhotoControl .upload-photo-wrapper {
      margin-bottom: 16px; }
      .dacForm .dacPhotoControl .upload-photo-wrapper .add-photo {
        padding: 0; }
  .dacForm .ownershipStakeTitle {
    margin-bottom: 26px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    line-height: 33px; }
  .dacForm .metisToken {
    margin-bottom: 29px;
    text-align: center;
    font-size: 0;
    line-height: 32px; }
    .dacForm .metisToken .polisPhoto {
      width: 32px;
      height: 32px;
      overflow: hidden;
      border-radius: 50%;
      vertical-align: -10px; }
    .dacForm .metisToken .metisTokenTitle {
      margin-left: 12px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      line-height: 32px; }
  .dacForm .formActionBar {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .dacForm .formActionBar :global .btn-prev {
      width: 95px; }
  .dacForm .metisTokenTips {
    margin-bottom: 32px; }
  .dacForm .privacyPolicyItem {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 20px; }
    .dacForm .privacyPolicyItem :global .MuiFormControlLabel-root {
      margin-right: 3px; }
      .dacForm .privacyPolicyItem :global .MuiFormControlLabel-root .MuiIconButton-colorSecondary {
        color: rgba(255, 255, 255, 0.2); }
        .dacForm .privacyPolicyItem :global .MuiFormControlLabel-root .MuiIconButton-colorSecondary.Mui-checked {
          color: #cd3270; }
    .dacForm .privacyPolicyItem :global .MuiLink-root {
      margin: 0 2px;
      font-size: 14px;
      color: #63d8cd;
      text-decoration: underline; }
  .dacForm .dacInfo {
    position: relative;
    padding-left: 93px; }
    .dacForm .dacInfoPhoto {
      position: absolute;
      top: 0;
      left: 0;
      width: 64px;
      height: 64px;
      border-radius: 50%; }
    .dacForm .dacInfoDetail {
      min-height: 88px; }
      .dacForm .dacInfoDetailItem {
        position: relative;
        padding: 7px 0 7px 128px;
        min-height: 41px;
        line-height: 22px;
        font-weight: 400;
        color: #fff;
        font-size: 0; }
        .dacForm .dacInfoDetailItem .itemTitle,
        .dacForm .dacInfoDetailItem .itemValue {
          font-size: 14px; }
        .dacForm .dacInfoDetailItem .itemTitle {
          position: absolute;
          top: 7px;
          left: 0;
          z-index: 0;
          width: 128px; }
  .dacForm .formControlItem {
    margin-bottom: 10px !important;
    padding-left: 142px !important; }
  .dacForm :global .MuiInputLabel-root {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 48px;
    transform: inherit; }
    .dacForm :global .MuiInputLabel-root.Mui-focused {
      color: #fff; }
  .dacForm :global .MuiInput-root {
    margin-top: 0 !important;
    padding: 10px 16px;
    min-height: 48px;
    color: rgba(255, 255, 255, 0.3);
    background: #322735;
    border-radius: 4px; }
  .dacForm :global .MuiFormHelperText-root {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.3);
    line-height: 17px; }
  .dacForm :global .metis-value {
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -40px;
    width: 80px;
    min-height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.3);
    line-height: 22px;
    background: #110c12;
    border-radius: 4px;
    border: 0;
    text-align: center; }
    .dacForm :global .metis-value:focus {
      outline: none; }
    .dacForm :global .metis-value .MuiInput-formControl {
      min-height: 40px; }
    .dacForm :global .metis-value .MuiInput-input {
      text-align: center;
      line-height: 20px; }
  .dacForm :global .MuiInput-input {
    padding: 0;
    caret-color: #cd3270; }
  .dacForm :global .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.87); }

.metisTokenInfo {
  width: 100%;
  line-height: 20px; }
  .metisTokenInfoItem {
    width: 45%;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 20px;
    float: left; }
    .metisTokenInfoItem .itemTitle {
      margin-right: 8px; }
  .metisTokenInfoPhoto {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    vertical-align: -4px; }

.dacInfoTip {
  margin-top: 24px; }

.metisConfiguration .rowItem {
  margin-top: 12px; }
  .metisConfiguration .rowItem :global .MuiLink-root {
    margin-left: 34px;
    text-decoration: underline;
    color: #63d8cd; }

.expandMoreIcon {
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%; }

.dacFormAccordion :global .MuiAccordionSummary-root,
.dacFormAccordion :global .MuiCollapse-wrapper,
.dacFormAccordion :global .MuiCollapse-container,
.dacFormAccordion :global .MuiPaper-root {
  background-color: #110c12 !important;
  color: #fff !important; }

.dacFormAccordion :global .MuiAccordionDetails-root {
  padding: 24px 32px; }

.dacFormPhoto {
  text-align: center !important; }

.dacFormCategory {
  position: relative;
  padding: 8px 16px 0 11px !important;
  min-height: 48px;
  color: rgba(255, 255, 255, 0.3);
  background: #110c12;
  border-radius: 4px; }
  .dacFormCategory :global .react-tagsinput-tag {
    font-size: 14px;
    border: 0;
    color: #fff; }
    .dacFormCategory :global .react-tagsinput-tag:nth-of-type(n) {
      background-color: rgba(29, 126, 165, 0.6); }
    .dacFormCategory :global .react-tagsinput-tag:nth-of-type(2n) {
      background-color: rgba(150, 35, 127, 0.6); }
    .dacFormCategory :global .react-tagsinput-tag:nth-of-type(3n) {
      background-color: rgba(83, 11, 127, 0.6); }
  .dacFormCategory :global .react-tagsinput-input {
    width: 104px;
    font-family: inherit !important; }
    .dacFormCategory :global .react-tagsinput-input::placeholder {
      color: rgba(255, 255, 255, 0.15);
      font-size: 14px;
      font-weight: 400; }

.createButton {
  min-width: 150px; }
