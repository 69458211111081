/**
* font-size
**/
/**
* color
**/
.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 40px;
  padding-top: 24px;
  padding-right: 29px;
  padding-left: 32px;
  min-height: 48px;
  line-height: 48px; }
  .headerWrapper .logo {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer; }
    .headerWrapper .logo img {
      margin-right: 20px;
      width: 184px;
      object-fit: contain; }
  .headerWrapper .connectBtn {
    padding: 13px 16px;
    background: rgba(205, 50, 112, 0.8) linear-gradient(90deg, rgba(150, 35, 127, 0.8) 0%, rgba(210, 49, 105, 0.8) 100%);
    border: 1px solid rgba(150, 35, 127, 0.8);
    border-radius: 28px;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer; }
  .headerWrapper .userInfoContainer::after {
    content: '';
    display: table;
    clear: both; }
  .headerWrapper .userInfoContainer .userInfo {
    position: relative;
    z-index: 0;
    margin: 0 28px;
    height: 48px;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    line-height: 46px;
    border-top: 1px solid #af2788;
    border-right: 0;
    border-bottom: 1px solid #af2788;
    border-left: 0;
    border-image: linear-gradient(to left, #d23169, #8b1ca6);
    border-image-slice: 1;
    float: left;
    cursor: pointer; }
    .headerWrapper .userInfoContainer .userInfo::before, .headerWrapper .userInfoContainer .userInfo::after {
      content: '';
      position: absolute;
      top: -1px;
      z-index: 1;
      width: 48px;
      height: 48px; }
    .headerWrapper .userInfoContainer .userInfo::before {
      left: -28px;
      border-top: 1px solid #8b1ca6;
      border-right: 0;
      border-bottom: 1px solid #8b1ca6;
      border-left: 1px solid #8b1ca6;
      border-top-left-radius: 28px;
      border-bottom-left-radius: 28px; }
    .headerWrapper .userInfoContainer .userInfo::after {
      right: -28px;
      border-top: 1px solid #d23169;
      border-right: 1px solid #d23169;
      border-bottom: 1px solid #d23169;
      border-left: 0;
      border-top-right-radius: 28px;
      border-bottom-right-radius: 28px; }
    .headerWrapper .userInfoContainer .userInfoItem-item {
      padding: 0 2px; }
  .headerWrapper .vault {
    margin-right: 24px;
    height: 48px;
    float: left;
    border-radius: 24px;
    padding: 10px 14px;
    cursor: pointer;
    border: 1px solid #cd306b; }
    .headerWrapper .vault .icon {
      display: block;
      width: 28px;
      height: 28px;
      margin-right: 4px; }
  .headerWrapper .settingsBtn {
    margin-left: 24px;
    width: 48px;
    height: 48px;
    float: left;
    border-radius: 28px;
    border: 0;
    background-color: transparent;
    cursor: pointer; }
    .headerWrapper .settingsBtn .settingsIcon {
      display: block;
      width: 100%;
      height: 100%; }

.polisPopover :global .MuiPopover-paper {
  position: relative;
  margin-top: 18px;
  padding: 16px 20px;
  width: 340px;
  height: 218px;
  background-color: #000 !important;
  color: #fff;
  overflow-x: inherit;
  overflow-y: inherit; }
  .polisPopover :global .MuiPopover-paper::before {
    content: '';
    position: absolute;
    top: -29px;
    left: 50%;
    margin-left: -9px;
    width: 0;
    height: 0;
    border: 18px solid;
    border-color: transparent transparent #000; }

.polisPopover .polisPopoverInfo .MuiPaper-root {
  background-color: transparent; }

.polisPopover .polisPopoverInfo .polisTitle {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 22px; }

.polisPopover .polisPopoverInfo .polisAddressBar {
  position: relative;
  margin-bottom: 8px;
  padding: 13px 47px 13px 16px;
  height: 48px;
  background: #110c12;
  border-radius: 4px; }
  .polisPopover .polisPopoverInfo .polisAddressBar .polisAddress {
    display: block;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    line-height: 22px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis; }
  .polisPopover .polisPopoverInfo .polisAddressBar .copyBtn {
    position: absolute;
    top: 15px;
    right: 13px;
    padding: 0;
    margin: 0;
    min-width: 16px !important;
    background-color: transparent;
    border: 0;
    cursor: pointer; }
    .polisPopover .polisPopoverInfo .polisAddressBar .copyBtn img {
      display: block;
      width: 16px;
      height: 16px; }

.polisPopover .polisPopoverInfo .metisTestnetTips {
  margin-bottom: 22px;
  font-size: 12px;
  font-weight: 500;
  color: #63d8cd;
  line-height: 20px;
  text-transform: uppercase; }

.polisPopover .polisPopoverInfo .disconnectBtn {
  display: block;
  margin: 0 auto;
  width: 180px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  line-height: 28px;
  border: 0;
  background: #cd3270 linear-gradient(90deg, #96237f 0%, #d23169 100%);
  border-radius: 4px; }
