/**
* font-size
**/
/**
* color
**/
.wrapper {
  width: 900px;
  min-height: 300px;
  float: right;
  position: relative; }
  .wrapper .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .wrapper .subdirectory {
    background: #000;
    opacity: 0.88;
    border-radius: 4px;
    padding: 24px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 20px; }
    .wrapper .subdirectory .prefix {
      font-size: 14px;
      padding-right: 10px;
      flex-shrink: 0; }
    .wrapper .subdirectory .linkInput {
      outline: none;
      border: none;
      width: 640px;
      height: 48px;
      background: #322735;
      color: #fff;
      caret-color: #cd3270;
      margin-right: 20px;
      padding: 0 10px; }
  .wrapper .invitation {
    background: #000;
    opacity: 0.88;
    border-radius: 4px;
    padding: 24px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 20px; }
    .wrapper .invitation .options {
      margin-top: 40px;
      margin-bottom: 40px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: row;
      width: 100%; }
      .wrapper .invitation .options .item {
        width: 48%;
        flex-shrink: 0;
        flex-grow: 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; }
        .wrapper .invitation .options .item .copyLink {
          margin-top: 20px; }
        .wrapper .invitation .options .item .inviteCode {
          width: 70%; }
      .wrapper .invitation .options .line {
        width: 2px;
        height: 120px;
        background: #cd306b; }
    .wrapper .invitation .info {
      margin-top: 30px; }
