/**
* font-size
**/
/**
* color
**/
.dacMiningWrapper {
  position: relative;
  padding-left: 180px;
  padding-right: 120px;
  width: 761px;
  height: 200px;
  background: linear-gradient(270deg, rgba(87, 183, 187, 0.6) 0%, rgba(29, 126, 165, 0.6) 100%);
  border-radius: 4px; }
  .dacMiningWrapper .poolMenu {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 200px;
    background: linear-gradient(270deg, #57b7bb 0%, #1d7ea5 100%);
    border-radius: 4px;
    text-align: center;
    cursor: pointer; }
  .dacMiningWrapper .nextMenu {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 200px;
    cursor: pointer;
    background: linear-gradient(270deg, rgba(87, 183, 187, 0.4) 0%, rgba(29, 126, 165, 0.4) 100%);
    border-radius: 4px; }
  .dacMiningWrapper .dacMiningContent {
    display: flex;
    padding-left: 45px;
    width: 100%;
    height: 200px;
    align-items: center; }
  .dacMiningWrapper .dacMiningTitle {
    margin-top: 0;
    margin-bottom: 8px; }
  .dacMiningWrapper .dissolutionInfo {
    padding-left: 12px;
    width: 384px;
    height: 64px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px; }
    .dacMiningWrapper .dissolutionInfo .iconRemind {
      margin-right: 8px;
      width: 20px;
      height: 20px; }
  .dacMiningWrapper .apyInfo {
    padding: 0 16px;
    width: fit-content;
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px; }
  .dacMiningWrapper .poolOpenDateTips {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 22px; }
  .dacMiningWrapper .cutdown .items {
    font-size: 0; }
    .dacMiningWrapper .cutdown .items li {
      display: inline-block;
      vertical-align: top; }
      .dacMiningWrapper .cutdown .items li .number {
        position: relative;
        display: block;
        width: 58px;
        height: 38px; }
        .dacMiningWrapper .cutdown .items li .number em {
          position: relative;
          z-index: 1;
          display: block;
          padding-left: 6px;
          width: 100%;
          font-size: 26px;
          font-style: normal;
          font-weight: bold;
          color: #fff;
          line-height: 38px;
          text-align: center;
          letter-spacing: 16px; }
        .dacMiningWrapper .cutdown .items li .number::before, .dacMiningWrapper .cutdown .items li .number::after {
          position: absolute;
          top: 0;
          z-index: 0;
          content: '';
          display: inline-block;
          width: 28px;
          height: 38px;
          background: #0e1427;
          border-radius: 4px;
          text-align: center;
          vertical-align: top; }
        .dacMiningWrapper .cutdown .items li .number::before {
          left: 0; }
        .dacMiningWrapper .cutdown .items li .number::after {
          right: 0; }
      .dacMiningWrapper .cutdown .items li .div {
        display: inline-block;
        padding: 0 10px;
        font-size: 14px;
        line-height: 38px;
        text-align: center;
        vertical-align: top; }
      .dacMiningWrapper .cutdown .items li .label {
        margin-top: 6px;
        display: block;
        font-size: 10px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.3);
        line-height: 14px;
        text-align: center; }
