/**
* font-size
**/
/**
* color
**/
.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px; }
  .row.md {
    margin-top: 14px; }
  .row.small {
    margin-top: 12px; }
  .row.none {
    margin-top: 0; }
  .row.alignCenter {
    width: 100%;
    justify-content: center;
    align-items: center; }
  .row.between {
    width: 100%;
    justify-content: space-between;
    align-items: center; }
  .row.start {
    justify-content: flex-start; }
  .row.center {
    justify-content: center; }
  .row.end {
    justify-content: flex-end; }
