/**
* font-size
**/
/**
* color
**/
.actionContainer {
  width: 800px;
  margin: 0 auto 48px;
  content: '';
  display: table;
  clear: both; }

.card {
  position: relative;
  width: 380px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer; }
  .card.createDac {
    float: left;
    background: linear-gradient(90deg, #96237f 0%, #d23169 100%); }
  .card.openExistingDac {
    float: right;
    background: linear-gradient(90deg, #530b7f 0%, #8b1ca6 100%); }
  .card.loading {
    float: left;
    background: linear-gradient(90deg, #96237f 0%, #d23169 100%); }
  .cardContent {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%; }
  .cardIcon {
    display: block;
    margin: 0 auto;
    width: 90px;
    height: 90px; }
    .cardIcon img {
      display: block;
      width: 90px;
      height: 90px;
      object-fit: cover;
      border-radius: 50%; }
  .card .dacRole {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 116px;
    height: 36px;
    background: #d8d8d8 linear-gradient(90deg, #96237f 0%, #d23169 100%);
    border-radius: 0px 4px 0px 16px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    line-height: 36px; }
    .card .dacRole.member {
      background: #d8d8d8 linear-gradient(90deg, #fca700 0%, #d23169 100%); }
    .card .dacRole.creator {
      background: #d8d8d8 linear-gradient(90deg, #96237f 0%, #d23169 100%); }
  .cardTitle {
    margin-top: 12px !important; }
  .cardDescription {
    margin-top: 6px !important; }
