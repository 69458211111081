/**
* font-size
**/
/**
* color
**/
.wrapper .modalTitle {
  line-height: 40px; }

.wrapper .modalPromptText {
  margin-top: 16px;
  line-height: 25px; }

.wrapper .modalActionBar {
  margin-top: 40px; }

.wrapper .connectBtn {
  margin: 0 10px;
  width: 180px; }
