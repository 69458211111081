/**
* font-size
**/
/**
* color
**/
.uploadScreenshots {
  position: relative;
  display: block;
  padding: 13px 48px 13px 16px;
  width: 100%;
  min-height: 48px;
  overflow: hidden;
  cursor: pointer;
  background: #110c12;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 22px; }
  .uploadScreenshots .filename {
    display: block;
    width: 100%;
    height: 32px;
    line-height: 32px;
    border: 0;
    color: #fff;
    background-color: transparent; }
    .uploadScreenshots .filename:focus-visible, .uploadScreenshots .filename:focus {
      border: 0;
      outline: none; }
  .uploadScreenshots .previewImg {
    width: 100%;
    height: 100%; }
  .uploadScreenshots .uploadMenu {
    position: absolute;
    top: 50%;
    right: 16px;
    z-index: 10;
    margin-top: -15px;
    width: 30px;
    height: 30px; }
