@mixin title {
    margin-bottom: 30px;
    font-size: 1.6rem;
    font-weight: $font-bold;
    line-height: 2;
    color: $white;
}

@mixin sub-title {
    font-size: 1.2rem;
    line-height: 2;
}

@mixin description {
    font-size: 1.2rem;
    line-height: 3;
}

@mixin sub-description {
    font-size: 1rem;
    line-height: 3;
}

@mixin redGradient {
    background: $pink linear-gradient(90deg, #96237f 0%, #d23169 100%);
}

@mixin redGradientLight {
    background: rgba($pink, 0.8)
        linear-gradient(
            90deg,
            rgba($pinkDark, 0.8) 0%,
            rgba($pinkLight, 0.8) 100%
        );
}

@mixin clearfix {
    content: '';
    display: table;
    clear: both;
}
