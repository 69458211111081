/**
* font-size
**/
/**
* color
**/
.searchContainer {
  margin: 0 auto 110px;
  padding: 24px 40px;
  width: 894px;
  min-height: 698px;
  background: rgba(0, 0, 0, 0.8); }
  .searchContainer .actionBar {
    margin-bottom: 40px; }
    .searchContainer .actionBar .btnBack {
      padding: 6px 8px;
      width: 95px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #fff;
      font-size: 12px;
      font-weight: 400;
      color: #fff;
      line-height: 26px;
      text-align: center; }
  .searchContainer .searchForm {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row; }
  .searchContainer .searchLabel {
    width: 134px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 48px;
    transform: inherit; }
  .searchContainer .searchInput {
    flex: 1;
    padding: 8px 16px;
    min-height: 48px;
    color: rgba(255, 255, 255, 0.3);
    background: #322735;
    border-radius: 4px;
    border: 0; }
  .searchContainer .searchButton {
    margin-left: 16px;
    width: 120px; }
  .searchContainer :global .MuiInputBase-input {
    caret-color: #cd3270; }
  .searchContainer :global .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.87); }
  .searchContainer .loadingContainer {
    padding-top: 25%;
    width: 100%;
    height: 486px;
    text-align: center; }
  .searchContainer .searchResult {
    padding: 24px 0;
    width: 100%;
    min-height: 470px; }
  .searchContainer .searchDacItems {
    width: 100%;
    max-width: 910px; }
    .searchContainer .searchDacItems .searchDacItem {
      background: rgba(0, 0, 0, 0.6);
      border-left: 4px solid rgba(205, 50, 112, 0.95);
      cursor: pointer;
      margin: 0 10px 16px 0;
      padding-right: 30px; }
      .searchContainer .searchDacItems .searchDacItem.isActive {
        background: rgba(255, 255, 255, 0.3); }
        .searchContainer .searchDacItems .searchDacItem.isActive::after {
          content: '';
          position: absolute;
          bottom: -12px;
          left: 50%;
          margin-left: -12px;
          width: 0;
          height: 0;
          border-width: 12px 12px 0;
          border-style: solid;
          border-color: rgba(255, 255, 255, 0.3) transparent transparent; }
      .searchContainer .searchDacItems .searchDacItem .searchDacPhoto {
        margin: 0 auto;
        display: block;
        width: 48px;
        height: 48px;
        background: #fff;
        border-radius: 50%; }
      .searchContainer .searchDacItems .searchDacItem .dacInnerText {
        margin-bottom: 20px;
        display: block;
        padding: 0 12px;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center; }
      .searchContainer .searchDacItems .searchDacItem .searchDacText {
        display: flex;
        flex: 1 1 auto;
        margin: 0 45px 0 16px;
        height: 48px;
        justify-content: space-between; }
      .searchContainer .searchDacItems .searchDacItem .searchDacTitle {
        line-height: 48px;
        width: 104px;
        font-size: 14px;
        overflow: hidden;
        color: #fff;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .searchContainer .searchDacItems .searchDacItem .searchDacIntroduction {
        background: #110c12;
        width: 80%;
        max-width: 456px;
        font-size: 12px;
        color: #fff;
        padding: 10px 18px;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .searchContainer .searchDacItems .searchDacItem .btnSubmit {
        padding: 0px 24px;
        height: 40px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 28px;
        border: 1px solid #8b1ca6;
        background: #cd3270 linear-gradient(90deg, #96237f 0%, #d23169 100%); }
  .searchContainer .dacPaginationCon {
    font-size: 14px;
    display: flex;
    justify-content: flex-end; }
    .searchContainer .dacPaginationCon .dacPaginationText {
      line-height: 32px; }
    .searchContainer .dacPaginationCon .dacPagination :global .MuiPaginationItem-root,
    .searchContainer .dacPaginationCon .dacPagination :global .MuiButtonBase-root {
      color: #fff; }
      .searchContainer .dacPaginationCon .dacPagination :global .MuiPaginationItem-root.Mui-selected,
      .searchContainer .dacPaginationCon .dacPagination :global .MuiButtonBase-root.Mui-selected {
        color: #cd3270; }
