/**
* font-size
**/
/**
* color
**/
.wrapper {
  margin-top: 40px;
  width: 900px;
  float: right;
  padding: 24px;
  background: rgba(0, 0, 0, 0.8); }
  .wrapper .title {
    margin-bottom: 24px;
    color: #bb2e63;
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    text-align: center; }
  .wrapper .guideWrapper {
    margin-bottom: 24px; }
    .wrapper .guideWrapper .subTitle {
      color: #bb2e63;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 8px; }
    .wrapper .guideWrapper li,
    .wrapper .guideWrapper p,
    .wrapper .guideWrapper dt,
    .wrapper .guideWrapper dd {
      font-size: 14px;
      font-weight: 400;
      color: #fcfcfc;
      line-height: 24px;
      margin-bottom: 4px;
      padding-left: 30px;
      text-indent: -30px; }
    .wrapper .guideWrapper dd {
      text-indent: -12px; }
    .wrapper .guideWrapper img {
      margin-top: 24px; }
  .wrapper .container {
    padding: 64px 40px;
    width: 100%;
    min-height: 680px;
    background-color: black; }
  .wrapper .videoWrapper {
    margin: 0 auto;
    width: 480px; }
    .wrapper .videoWrapper :global .video-react .video-react-poster {
      width: 100%;
      height: 270px;
      background-color: #1d0619; }
    .wrapper .videoWrapper :global .video-react .video-react-big-play-button {
      top: 50%;
      left: 50%;
      margin-top: -26px;
      margin-left: -26px;
      width: 48px;
      height: 48px;
      background: #cd3270;
      border: 0;
      border-radius: 50%; }
      .wrapper .videoWrapper :global .video-react .video-react-big-play-button:before {
        top: 4px;
        font-size: 40px;
        color: black; }
