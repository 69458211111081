/**
* font-size
**/
/**
* color
**/
.progressBar {
  margin-bottom: 48px;
  padding-top: 48px;
  padding-bottom: 56px;
  position: relative; }
  .progressBar :global .input-range__label {
    bottom: -47px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #fff; }
    .progressBar :global .input-range__label--max {
      right: 20px; }
  .progressBar :global .input-range__track {
    height: 8px;
    background-color: rgba(216, 216, 216, 0.2);
    border-radius: 6px; }
  .progressBar :global .input-range__track--active {
    background: #cd3270 linear-gradient(90deg, #96237f 0%, #d23169 100%); }
  .progressBar :global .input-range__slider {
    z-index: 10;
    margin-top: -18px;
    width: 24px;
    height: 24px;
    background: #cd3270 linear-gradient(90deg, #96237f 0%, #d23169 100%);
    border: 0; }
  .progressBar :global .input-range__label--value {
    margin-top: -34px; }
    .progressBar :global .input-range__label--value .input-range__label-container {
      display: inline-block;
      margin-left: -40px;
      left: 14px;
      width: 60px;
      height: 29px;
      background: #d23169;
      border-radius: 2px;
      text-align: center;
      line-height: 29px;
      font-size: 14px;
      font-weight: 400;
      color: #fff; }
      .progressBar :global .input-range__label--value .input-range__label-container::after {
        content: '';
        position: absolute;
        bottom: -19px;
        left: 50%;
        transform: translateX(-50%);
        border: 11px solid transparent;
        border-top: 11px solid #d23169; }
  .progressBar :global .MuiInput-input {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -40px;
    width: 80px;
    height: 40px;
    background: #110c12;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #cd3270; }
    .progressBar :global .MuiInput-input:focus {
      outline: none; }
