/**
* font-size
**/
/**
* color
**/
.loading {
  color: #cd3270; }

@keyframes rotateAnimation {
  100% {
    transform: rotate(360deg); } }

.timeLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  flex-direction: column; }

.timeModalContent {
  border: none;
  background: transparent; }
