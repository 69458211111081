/**
* font-size
**/
/**
* color
**/
.wrapper {
  position: relative;
  padding: 12px 0;
  width: 650px;
  min-height: 602px; }
  .wrapper .photoBar {
    margin-bottom: 28px;
    text-align: center; }
    .wrapper .photoBar .photo {
      margin: 0 auto; }
    .wrapper .photoBar .photoTips {
      margin-top: 16px;
      font-size: 20px;
      font-weight: 600;
      color: #fff;
      line-height: 28px; }
      .wrapper .photoBar .photoTips img {
        margin-left: 8px;
        vertical-align: -4px; }
  .wrapper .profileInfo {
    margin: 24px auto 0;
    max-width: 500px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 22px;
    text-align: center; }
  .wrapper .profileForm,
  .wrapper .reportBugForm {
    margin: 0 auto;
    max-width: 480px;
    color: #fff; }
    .wrapper .profileForm .formControl,
    .wrapper .reportBugForm .formControl {
      margin-bottom: 24px; }
      .wrapper .profileForm .formControl :global .MuiFormLabel-root,
      .wrapper .reportBugForm .formControl :global .MuiFormLabel-root {
        position: inherit;
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        line-height: 22px;
        transform: translate(0, 1.5px) scale(1); }
      .wrapper .profileForm .formControl :global .MuiInput-root,
      .wrapper .reportBugForm .formControl :global .MuiInput-root {
        padding: 13px 16px;
        min-height: 48px;
        background: #110c12;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        line-height: 22px; }
      .wrapper .profileForm .formControl :global .MuiInputBase-input,
      .wrapper .reportBugForm .formControl :global .MuiInputBase-input {
        caret-color: #cd3270; }
      .wrapper .profileForm .formControl :global .MuiInput-underline:hover:not(.Mui-disabled):before,
      .wrapper .reportBugForm .formControl :global .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 1px solid rgba(0, 0, 0, 0.87); }
      .wrapper .profileForm .formControl .mmInputLable,
      .wrapper .reportBugForm .formControl .mmInputLable {
        color: #322735 !important; }
      .wrapper .profileForm .formControl .screenshots,
      .wrapper .reportBugForm .formControl .screenshots {
        background: #322735; }
    .wrapper .profileForm .fileHelper,
    .wrapper .reportBugForm .fileHelper {
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.3);
      line-height: 22px;
      text-align: center; }
  .wrapper .reportBugForm .formControl :global .MuiInput-root {
    background: #322735; }
  .wrapper .modalActionBar {
    margin-top: 40px; }
  .wrapper .connectBtn {
    margin: 0 10px;
    width: 240px; }
  .wrapper .loadingContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    text-align: center;
    padding-top: calc(50% - 21px); }
