/**
* font-size
**/
/**
* color
**/
.wrapper {
  width: 420px;
  flex-direction: column; }
  .wrapper .content {
    margin-top: 30px; }
    .wrapper .content .detail {
      margin-top: 30px; }
    .wrapper .content .inputWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-top: 20px;
      height: 40px; }
      .wrapper .content .inputWrapper .input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 0;
        flex-grow: 1;
        background: #322735; }
        .wrapper .content .inputWrapper .input .logo {
          width: 28px;
          height: 28px;
          margin-right: 10px;
          flex-shrink: 0; }
        .wrapper .content .inputWrapper .input input {
          outline: none;
          border: none;
          height: 100%;
          flex-grow: 1;
          background: transparent;
          color: #fff;
          caret-color: #cd3270; }
        .wrapper .content .inputWrapper .input .balance {
          flex-shrink: 0;
          padding-left: 10px; }
      .wrapper .content .inputWrapper .max {
        flex-shrink: 0;
        width: 60px;
        height: 48px;
        margin-left: 10px; }
    .wrapper .content .agreement {
      margin-top: 20px;
      line-height: 1.4;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start; }
      .wrapper .content .agreement :global .MuiButtonBase-root {
        padding: 0;
        margin-right: 10px; }
      .wrapper .content .agreement a {
        color: #63d8cd;
        text-decoration: underline; }
  .wrapper .transLoading {
    width: 88px;
    height: 88px;
    color: #00dacc;
    margin-top: 60px;
    animation: rotate 3s linear infinite; }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  .wrapper .successIcon {
    width: 120px;
    height: 120px;
    margin-top: 60px; }
  .wrapper .infoImg {
    width: 110px;
    height: 110px;
    margin-top: 60px; }
  .wrapper .footer {
    margin-top: 100px; }
    .wrapper .footer .button {
      width: 160px; }
  .wrapper .block {
    margin-top: 20px;
    background: #110c12;
    padding: 12px; }
  .wrapper :global a {
    color: #63d8cd;
    text-decoration: underline; }
