/**
* font-size
**/
/**
* color
**/
.wrapper {
  width: 900px;
  float: right; }
  .wrapper .list {
    margin-top: 30px; }
    .wrapper .list :global .MuiTableContainer-root .MuiTable-root {
      background-color: #000; }
      .wrapper .list :global .MuiTableContainer-root .MuiTable-root .MuiTableCell-head {
        color: #7493ad;
        border: none; }
      .wrapper .list :global .MuiTableContainer-root .MuiTable-root .MuiTableCell-body {
        color: #fff;
        border: none;
        padding: 12px; }
  .wrapper .href {
    color: #cd3270;
    text-decoration: underline; }
