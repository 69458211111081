/**
* font-size
**/
/**
* color
**/
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 900 !important; }

.content {
  position: relative;
  min-width: 200px;
  max-width: 95vw;
  padding: 20px;
  outline: none;
  border: 1px solid #cd3270;
  background: #000; }

.close {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10;
  color: #cd3270;
  cursor: pointer;
  width: 24px !important;
  height: 24px !important; }

.customWrapper {
  padding: 20px;
  display: flex;
  width: 360px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden; }
  .customWrapper .imgContainer {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    margin: 15px 0; }
    .customWrapper .imgContainer .image {
      width: 80px;
      height: 80px; }
  .customWrapper .footer {
    width: 100%;
    margin-top: 20px; }
    .customWrapper .footer .button {
      width: 180px; }

@media screen and (max-width: 768px) {
  .customWrapper {
    width: calc(100vw - 10rem); } }
