/**
* font-size
**/
/**
* color
**/
.wrapper {
  position: relative;
  margin: 0 auto;
  padding: 32px;
  width: 990px;
  min-height: 698px;
  background-color: rgba(0, 0, 0, 0.8); }
  .wrapper .authInfo {
    position: relative;
    margin-bottom: 86px;
    padding-left: 56px; }
    .wrapper .authInfo .authPhoto {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 50%;
      background-color: #d8d8d8; }
      .wrapper .authInfo .authPhoto img {
        display: block;
        height: 100%;
        width: 100%;
        border-radius: 50%; }
  .wrapper .inviteTitle {
    margin: 0 auto 32px;
    max-width: 578px; }
  .wrapper .dacInfo {
    margin: 0 auto;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 500px;
    box-sizing: border-box;
    padding: 28px 24px;
    flex-direction: row;
    border: 1px solid rgba(255, 255, 255, 0.3); }
    .wrapper .dacInfo .dacPhoto {
      margin-right: 24px;
      width: 48px;
      height: 48px;
      overflow: hidden;
      border-radius: 50%;
      background-color: #d8d8d8;
      flex-shrink: 0;
      flex-grow: 0; }
      .wrapper .dacInfo .dacPhoto img {
        display: block;
        height: 100%;
        width: 100%;
        border-radius: 50%; }
    .wrapper .dacInfo .info {
      flex-shrink: 0;
      width: 280px;
      box-sizing: border-box;
      padding-right: 20px; }
    .wrapper .dacInfo .members {
      flex-shrink: 0;
      flex-grow: 0;
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      padding-left: 22px; }
  .wrapper .metisTips {
    margin: 0 auto 75px;
    max-width: 500px; }
  .wrapper .actionBar .btnSubmit {
    width: 160px;
    height: 40px; }

.loading {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.error {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff; }
