* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba($black, 0);
}

*::before,
*::after {
    box-sizing: inherit;
}

img {
    border: none;
    object-fit: contain;
}

a {
    outline: none;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

a img {
    vertical-align: top;
}

ul,
ol,
li {
    list-style: none;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

html {
    text-size-adjust: 100%;
}

body {
    padding-bottom: 74px;
    font-family: 'PingFangSC-Semibold', 'PingFang SC', 'DINAlternate-Bold',
        'DINAlternate', 'sans-serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-size: 14px;
    color: $white;
    background-color: #1a1a1a;
    background-image: url('/static/images/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

textarea {
    padding: 0.8em;
    overflow-x: hidden;
    overflow-y: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.root,
.app-wrapper {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
}

.app-container {
    margin: 0 auto;
    padding-bottom: 74px;
    width: 1180px;
}

.app-container::after {
    content: '';
    display: table;
    clear: both;
}

.text-error {
    position: absolute;
    bottom: -20px;
    left: 0;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #f44336;
}

.react-tagsinput--focused {
    border-color: #110c12 !important;
}

.MuiInput-root {
    border: 1px solid #110c12;
}

.MuiInput-root.MuiInput-underline::after {
    /* display: none !important; */
    border-bottom: 0;
}

.MuiInput-root.Mui-error {
    border: 1px solid #f5222d !important;
}

.MuiInput-root.Mui-error::after {
    position: absolute;
    top: 50%;
    right: 16px;
    left: inherit;
    z-index: 1;
    margin-top: -11px;
    content: '';
    width: 22px;
    height: 22px;
    border-bottom: 0;
    background-image: url('/static/images/ic_red-cross.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: content;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}
