/**
* font-size
**/
/**
* color
**/
.wrapper {
  width: 900px;
  float: right; }
  .wrapper .block {
    background: #000;
    opacity: 0.88;
    border-radius: 4px;
    width: 100%;
    padding: 20px; }
    .wrapper .block img {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin-right: 12px; }
  .wrapper .invite {
    background: #000;
    opacity: 0.88;
    border-radius: 4px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 100%;
    padding: 20px; }
    .wrapper .invite .inviteButton {
      margin-top: 40px;
      width: 160px; }
  .wrapper .list {
    margin-top: 30px;
    box-sizing: border-box;
    padding: 20px;
    background: #000;
    opacity: 0.88;
    border-radius: 4px; }
    .wrapper .list .data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 52px; }
      .wrapper .list .data > div {
        width: 30%;
        flex-shrink: 0;
        flex-grow: 0; }
    .wrapper .list :global .MuiTableContainer-root .MuiTable-root {
      background-color: #000; }
      .wrapper .list :global .MuiTableContainer-root .MuiTable-root .MuiTableCell-head {
        color: #7493ad;
        border: none; }
      .wrapper .list :global .MuiTableContainer-root .MuiTable-root .MuiTableCell-body {
        color: #fff;
        border: none;
        padding: 12px; }
    .wrapper .list .mainData {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px; }
      .wrapper .list .mainData .item {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; }
  .wrapper .rewards {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
    border-radius: 4px;
    overflow: hidden; }
    .wrapper .rewards .item {
      background: #000;
      opacity: 0.88;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 10px;
      height: 170px;
      flex-shrink: 0;
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 0px; }
      .wrapper .rewards .item .claim {
        width: 160px;
        height: 38px; }
      .wrapper .rewards .item .withdraw {
        margin-right: 8px; }
  .wrapper .inviteMore {
    color: #cd3270;
    border: none;
    text-decoration: underline; }
  .wrapper .guide {
    margin-top: 30px;
    background: #000;
    opacity: 0.88;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 20px; }
