/**
* font-size
**/
/**
* color
**/
.list {
  margin-top: 18px;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  background: rgba(0, 0, 0, 0.87);
  border-radius: 4px; }
  .list .row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row; }
    .list .row div {
      flex-shrink: 0;
      flex-grow: 0;
      text-align: center;
      height: 44px;
      box-sizing: border-box;
      padding: 12px;
      font-size: 14px;
      font-weight: 400; }
    .list .row .rank {
      width: 10%;
      text-align: right;
      padding-left: 0;
      padding-right: 20px; }
    .list .row .name {
      width: 30%; }
    .list .row .power {
      width: 20%; }
    .list .row .member {
      width: 20%; }
    .list .row .bonus {
      width: 20%; }
  .list .head .row div {
    font-weight: 500;
    padding: 16px;
    color: #7493ad;
    height: 56px;
    font-size: 14px; }

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #314f68 #17344b; }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px; }
