/**
* font-size
**/
$font-p2p: 'PressStart2P', 'PingFangSC-Semibold', 'PingFang SC',
    'Helvetica Neue', serif;
$font-zh: 'zh';
$break-point-laptop: 1024px;
$break-point-tablet: 768px;

$h-font-size-extra-small: 12px;
$h-font-size-small: 14px;
$h-font-size-medium: 16px;
$h-font-size-large: 18px;
$h-font-size-extra-large: 20px;
$h-font-size-extra-extra-large: 72px;

$p-font-size-extra-small: 10px;
$p-font-size-small: 12px;
$p-font-size-medium: 14px;
$p-font-size-large: 16px;
$p-font-size-extra-large: 18px;
$p-font-size-extra-extra-large: 20px;

$font-light: 200;
$font-normal: 400;
$font-semi-bold: 500;
$font-bold: 700;

/**
* color
**/
$pink: #cd3270;
$pinkDark: #96237f;
$pinkLight: #d23169;
$purpleDark: #530b7f;
$purpleLight: #8b1ca6;
$white: #fff;
$black: #000;
$darkGreen: #1e3e59;
$lightGreen: #64d8cd;
$darkBlue: #0c1830;
$yellow: #ffb200;
$lightDark: #0b1829;
$deepDark: #000206;
$deepDarkGrey: #110c12;
$lightGrey: #322735;
$darkGrey: #222e3d;
$cyanBlue: #63d8cd;
$cyanBlueDark: #1d7ea5;
$cyanBlueLight: #57b7bb;
