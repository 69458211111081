/**
* font-size
**/
/**
* color
**/
.metisButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff; }
  .metisButton.middle {
    padding: 15px;
    font-size: 14px;
    border-radius: 8px; }
  .metisButton.normal {
    padding: 12px;
    font-size: 14px;
    border-radius: 4px; }
  .metisButton.primary {
    background: #cd3270 linear-gradient(90deg, #96237f 0%, #d23169 100%);
    color: #fff; }
  .metisButton.transparent {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: #fff; }
    .metisButton.transparent.normal {
      padding: 10px; }
    .metisButton.transparent:hover, .metisButton.transparent:focus, .metisButton.transparent:active {
      color: #fff; }
  .metisButton.disabled {
    background: rgba(205, 50, 122, 0.7);
    color: #e4dfdf;
    border-image: none;
    box-shadow: none;
    cursor: not-allowed; }
    .metisButton.disabled.transparent {
      background: transparent;
      color: #e4dfdf; }
    .metisButton.disabled:hover, .metisButton.disabled:focus, .metisButton.disabled:active {
      background: rgba(205, 50, 122, 0.7);
      color: #e4dfdf;
      cursor: not-allowed; }
      .metisButton.disabled:hover.transparent, .metisButton.disabled:focus.transparent, .metisButton.disabled:active.transparent {
        background: transparent;
        color: #e4dfdf; }
  .metisButton.loading {
    color: transparent; }
  .metisButton .loadingIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
    color: #ccc; }
