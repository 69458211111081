.labelHelp {
    display: inline-block;

    .iconHelp {
        width: 18px;
        height: 18px;
        vertical-align: -4px;
        cursor: pointer;
    }
}
