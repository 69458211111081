.wrapper {
    width: 900px;
    margin: 0 auto;

    .rankingTitle {
        display: flex;
        justify-content: space-between;
    }

    .back {
        width: 95px;
        height: 40px;
    }
}
