/**
* font-size
**/
/**
* color
**/
.wrapper {
  width: 420px;
  flex-direction: column; }
  .wrapper .content {
    margin-top: 30px; }
    .wrapper .content .detail {
      margin-top: 30px; }
    .wrapper .content .inputWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-top: 20px;
      height: 40px;
      width: 100%; }
      .wrapper .content .inputWrapper .input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 0;
        flex-grow: 1;
        background: #322735; }
        .wrapper .content .inputWrapper .input .logo {
          width: 28px;
          height: 28px;
          margin-right: 10px;
          flex-shrink: 0; }
        .wrapper .content .inputWrapper .input input {
          outline: none;
          border: none;
          height: 100%;
          flex-grow: 1;
          flex-shrink: 1;
          min-width: 100px;
          background: transparent;
          color: #fff;
          caret-color: #cd3270; }
        .wrapper .content .inputWrapper .input .balance {
          display: flex;
          box-sizing: border-box;
          padding-left: 10px;
          flex-shrink: 0;
          flex-wrap: wrap; }
      .wrapper .content .inputWrapper .max {
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        margin-left: 10px; }
    .wrapper .content .agreement {
      margin-top: 20px;
      line-height: 1.4;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start; }
      .wrapper .content .agreement :global .MuiButtonBase-root {
        padding: 0;
        margin-right: 10px; }
      .wrapper .content .agreement :global .MuiTypography-body1 {
        font-size: 14px; }
      .wrapper .content .agreement :global .MuiFormControlLabel-root {
        margin-right: 3px; }
        .wrapper .content .agreement :global .MuiFormControlLabel-root .MuiIconButton-colorSecondary {
          color: rgba(255, 255, 255, 0.2); }
          .wrapper .content .agreement :global .MuiFormControlLabel-root .MuiIconButton-colorSecondary.Mui-checked {
            color: #cd3270; }
      .wrapper .content .agreement :global .MuiLink-root {
        margin: 0 2px;
        font-size: 14px;
        color: #63d8cd;
        text-decoration: underline; }
      .wrapper .content .agreement a {
        color: #63d8cd;
        text-decoration: underline; }
  .wrapper :global a {
    color: #63d8cd;
    text-decoration: underline; }
  .wrapper .transLoading {
    width: 88px;
    height: 88px;
    color: #57b7bb;
    margin-top: 60px;
    animation: rotate 3s linear infinite; }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  .wrapper .successIcon {
    width: 120px;
    height: 120px;
    margin-top: 60px; }
  .wrapper .warningIcon {
    width: 66px;
    height: 66px;
    margin-top: 60px; }
  .wrapper .footer {
    margin-top: 50px; }
    .wrapper .footer .button {
      width: 160px; }
  .wrapper .block {
    margin-top: 20px;
    padding: 10px 12px;
    min-height: 48px;
    line-height: 28px;
    background: #110c12; }
