/**
* font-size
**/
/**
* color
**/
.header {
  margin-bottom: 32px;
  text-align: center; }
  .headerTitle {
    margin-bottom: 22px;
    font-size: 72px;
    font-weight: 700;
    color: #fff;
    line-height: 84px;
    letter-spacing: 54px;
    -webkit-text-stroke: 2px #fff; }
  .headerDescription {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    line-height: 28px;
    letter-spacing: 5px; }

.metisContainer {
  text-align: center;
  margin-bottom: 48px; }

.pageContainer {
  margin: 0 auto;
  width: 800px; }
