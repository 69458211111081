.MuiDialog-paperWidthSm {
    background-color: rgba($black, 0.7) !important;
    border: 1px solid #c02d65;
}

.MuiDialogTitle-root,
.MuiDialogContent-root,
.MuiTypography-colorTextSecondary {
    color: $white !important;
}

.MuiPaper-root .MuiMenuItem-root {
    color: $white !important;
}

.MuiPaper-root .MuiMenuItem-root:hover {
    color: #c02d65 !important;
}

.customTable {
    .MuiTableRow-root {
        color: #000;
    }
    .MuiPaper-root {
        background: rgba($black, 0.87);
        border-radius: 4px;
    }

    .MuiTableCell-root {
        background: transparent;
        border: none;
        color: $white;
        text-align: center;
        background-color: rgba($black, 0.87);
    }

    .MuiTableHead-root {
        .MuiTableCell-root {
            color: #7493ad;
            font-size: 14px;
        }
    }

    .MuiTableBody-root {
        .MuiTableCell-root {
            color: $white !important;
            font-size: 14px !important;
            font-weight: 400 !important;
        }
    }

    .MuiTablePagination-toolbar {
        color: $white;

        .MuiSvgIcon-root {
            color: $white !important;
        }
    }

    .MuiButtonBase-root {
        color: $white !important;
    }

    .MuiTypography-root {
        color: $white;
    }
}

.MuiPopover-paper {
    background: #282727 !important;
}

.dacActionPopover {
    .MuiPopover-paper {
        position: relative;
        margin-top: 10px;
        padding-top: 10px;
        width: 195px;
        min-height: 79px;
        border: 1px solid $white;
        overflow-x: inherit;
        overflow-y: inherit;
        background-color: $deepDark !important;

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            margin-left: -10px;
            border: 10px solid transparent;
            border-bottom: 10px solid $white;
        }

        &::before {
            top: -20px;
            z-index: 0;
        }

        &::after {
            top: -16px;
            z-index: 1;
            margin-left: -8px;
            border: 8px solid transparent;
            border-bottom: 8px solid $deepDark !important;
        }

        .dissolveMenu {
            margin-top: 16px;
            text-align: center;
            cursor: pointer;
        }
    }
}

.MuiCollapse-wrapper,
.MuiPaper-root {
    background-color: #110c12 !important;
}

.MuiInputBase-root {
    color: $white;

    &.Mui-disabled {
        color: rgba($white, 0.4) !important;
    }
}
