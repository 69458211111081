/**
* font-size
**/
/**
* color
**/
.wrapper {
  width: 900px;
  margin: 0 auto; }
  .wrapper .btnBack {
    padding: 6px 8px;
    width: 95px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #fff;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    line-height: 26px;
    text-align: center;
    background: transparent; }
  .wrapper .data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    height: 160px;
    box-sizing: border-box;
    padding: 20px 32px;
    background: #000;
    opacity: 0.88;
    border-radius: 4px; }
    .wrapper .data .chartsWrapper {
      width: 128px;
      height: 128px; }
    .wrapper .data .withdraw {
      width: 100px; }
  .wrapper .blockWrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row; }
    .wrapper .blockWrapper .block {
      background: #000;
      opacity: 0.88;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 30px 10px;
      width: 48%; }
