/**
* font-size
**/
/**
* color
**/
.metisSelect {
  position: relative;
  display: inline-block;
  width: 220px;
  min-height: 48px; }
  .metisSelect .metisSelectContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    min-height: 48px;
    padding: 10px 22px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: 2px solid #cd3270;
    cursor: default;
    transition: all 0.5s ease-in-out;
    white-space: nowrap; }
  .metisSelect .selectMenu {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    line-height: 28px; }
    .metisSelect .selectMenu img {
      position: absolute;
      top: 10px;
      right: 12px;
      width: 24px;
      height: 24px; }
  .metisSelect .selectMenuList {
    transition: all 0.5s ease-in-out; }
    .metisSelect .selectMenuList li {
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.3);
      line-height: 34px;
      cursor: pointer; }
